import React from 'react';
import { BottomText, FooterText, InfoContainer, InfoWrapper, LeftDiv, MainSectionContainer, OverlappingText, RightImage, TopText } from './NewsInfoElements';


const NewsInfoSection = ({ isDarkMode, headline, description, img, alt, header, topLine, topLine2, underLine,  }) => {
    return (
        <InfoContainer>
        <InfoWrapper>
        <MainSectionContainer isDarkMode={isDarkMode}>
        <TopText>{topLine}</TopText>
                    <BottomText>{topLine2}</BottomText>
            <LeftDiv>
                <OverlappingText isDarkMode={isDarkMode}>
                {headline}
                 
                </OverlappingText>
            </LeftDiv>
            <TopText>{underLine}</TopText>
            <RightImage src={img} alt={alt} />
            <FooterText>{description}</FooterText>
        </MainSectionContainer>
        </InfoWrapper>
        </InfoContainer>
    );
};

export default NewsInfoSection;
