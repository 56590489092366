//HERO SECTION DATA

export const newsInfo1 = {
    topLine: "Feracho",
    topLine2: "Company",
    headline: "News at the Forefront",
    underLine: "Get the spiciest updates by subscribing!",
    description: "Stay Updated with FERACHO. From our latest product launches to exciting company news, our newsletter keeps you in the loop of all things FERACHO. Discover behind-the-scenes insights, exclusive interviews, and a glimpse into our future endeavors. It's your direct line to the heartbeat of our brand.",
    img: "https://ferachogroup.s3.amazonaws.com/images/NewsletterImages/NewsletterLong1.png", // Replace with the actual path to your image
    alt: "Illustration of Articles and Peppers, symbolizing FERACHO's news updates"

};

export const newsInfo2 = {
    topLine: "Feracho",
    topLine2: "Company",
    headline: "Celebrating Success Together",
    description: "Stay Updated with FERACHO. From our latest product launches to exciting company news, our newsletter keeps you in the loop of all things FERACHO. Discover behind-the-scenes insights, exclusive interviews, and a glimpse into our future endeavors. It's your direct line to the heartbeat of our brand.",
    img: "https://ferachogroup.s3.amazonaws.com/images/NewsletterImages/NewsletterLong2.png", // Replace with the actual path to another image
    alt: "Team celebrating success, embodying FERACHO's community spirit"

};








//OUR STORY DATA

//COMPANIES DATA

//NEWSLETTER DATA




///CONTACT US DATA