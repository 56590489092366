import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import dmode from '../../media/images/DarkMode.png';
import ferD from '../../media/images/Feracho-BlackMain.png'; // Dark mode company logo
import ferL from '../../media/images/Feracho-WhiteMain.png'; // Light mode company logo
import lmode from '../../media/images/LightMode.png';
import sig from '../../media/images/SunnyIslandLogo.png'; // Centered logo
import { CenterLogo, HamburgerMenu, Logo, LogoContainer, ModeSymbol, NavItem, NavItems, NavbarContainer, ToggleMode } from './NavabarElements';
const Navbar = ({isDarkMode, setIsDarkMode, isOpen, setIsOpen }) => {


  const [scrollY, setScrollY] = useState(0);
  const [scrollFade, setScrollFade] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (scrollY > 20) { // Adjust this value as needed
      controls.start({ opacity: 0 });
      setScrollFade(false)
    } else {
      controls.start({ opacity: 1 });
      setScrollFade(true)
    }
  }, [scrollY, controls, setScrollFade]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };
  return (
    <NavbarContainer scrollFade={scrollFade} as={motion.div} >
      <HamburgerMenu initial={{ opacity: 1 }}  onClick={() => setIsOpen(!isOpen)}>
        <div style={{ transform: isOpen ? 'rotate(-45deg) translate(-5px, 6px)' : '' }} />
        <div style={{ opacity: isOpen ? '0' : '1', transform: isOpen ? 'translateX(20px)' : '' }} />
        <div style={{ transform: isOpen ? 'rotate(45deg) translate(-5px, -6px)' : '' }} />
      </HamburgerMenu>

      
      <LogoContainer as={motion.div} initial={{ opacity: 1 }} >
  <Link to="/">
    <Logo src={isDarkMode ? ferL : ferD} alt="Company Logo" />
  </Link>
</LogoContainer>



      <CenterLogo  src={sig} alt="Feracho Signature" initial={{ opacity: 1 }}  />
  <NavItems as={motion.div} initial={{ opacity: 1 }} >

    <NavItem  as={motion.a}  href="/OurStory" animate={controls} >Our Story</NavItem>
    <NavItem as={motion.a}   href="/Companies" animate={controls} >Companies</NavItem>
    <NavItem as={motion.a}   href="/News" animate={controls} >Insider Updates</NavItem>
    <NavItem as={motion.a}   href="/Contact" animate={controls} >Contact</NavItem>

    <ToggleMode initial={{ opacity: 1 }}  className={isDarkMode ? 'dark' : ''} onClick={toggleDarkMode}>
      <ModeSymbol src={isDarkMode ? lmode : dmode} alt={isDarkMode ? 'Dark Mode Icon' : 'Light Mode Icon'} />

    </ToggleMode>
  </NavItems>
    </NavbarContainer>
  );
};

export default Navbar;


