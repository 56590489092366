import styled from 'styled-components';

export const SectionContainer = styled.section`
  height: ${({ imgStart }) => imgStart ? '2346px' : '1425px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;       // Ensures the background image covers the entire element
  background-position: center;  // Centers the background image
  background-repeat: no-repeat; // Prevents repeating the background image
  width: 100vw;
`;


export const ContentWrapper = styled.div`
   //top: ${({ imgStart }) => (imgStart ? '-10px' : '15%')};
  //position: relative;


`
export const ImageContainer = styled.img`
  width: 1157.579px;
  height: 1427px;
  //flex-shrink: 0;
  z-index: 0;
//transform: ;
  position: absolute;
  transform: ${({ imgStart }) => (imgStart ? 'scale(0.75) translateY(200px)' : '0')}; 
  left: ${({ imgStart }) => (imgStart ? 'auto' : '0')}; // Adjust based on imgStart
    right: ${({ imgStart }) => (imgStart ? '0px' : 'auto')}; // Adjust based on imgStart
  /* Background image styling goes here */
`;
export const TextWrap = styled.div`
z-index: 9;
//transform: tr;
top: 12%;
left: 13%;
//right: ${({ imgStart }) => (imgStart ? '10%' : '1%')}; 
left: ${({ imgStart }) => (imgStart ? '32.5%' : '13%')}; // Adjust based on imgStart
    top: ${({ imgStart }) => (imgStart ? '860px' : '12%')};
position: relative;

`
export const Header = styled.h1`
  display: flex;
  z-index: 1;
  width: 598px;
  height: 115px;
  flex-direction: column;
  justify-content: center;
  color: #000;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 44px;
  font-weight: 700;
  margin-bottom: -35px;
  line-height: 50.84px;
  letter-spacing: -0.56px;

`;

export const Subtitle = styled.h2`
  z-index: 1;
  display: flex;
  width: 550px;
  height: 219px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: 'Barlow', sans-serif;
margin-bottom: -20px;
  font-size: 16.5px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;

`;
