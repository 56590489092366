import React from 'react';
import { ButtonLong } from '../../../components/GlobalComponents/GlobalElements';
import { ContentWrapper, Header, ImageContainer, SectionContainer, Subtitle, TextWrap } from './LongElements';
const LongInfoSection = ({ imgStart, pageBg, header, description, btnLabel, textBg  }) => {


  return (
    <SectionContainer imgStart={imgStart} style={{ backgroundImage: `url(${pageBg})` }}>
     <ContentWrapper  imgStart={imgStart} >
      <ImageContainer imgStart={imgStart} src={textBg}/>
      <TextWrap  imgStart={imgStart} >
        <Header>{header}</Header>
        <Subtitle>{description}</Subtitle>
        {/* Add a button here if needed */}
        <ButtonLong>{btnLabel}</ButtonLong>
        </TextWrap>
        </ContentWrapper>
      {/* Other content goes here */}
    </SectionContainer>
  );
};

export default LongInfoSection;
