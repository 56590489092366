import { Link as LinkR } from 'react-router-dom';
import styled from 'styled-components';

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  //top: 3%;
  left: 0;
  right: 0;
    height: 100px;
  //background-color: #fff;
  background-color: ${({ scrollFade }) => (scrollFade ? '#fff' : 'none')};
  z-index: 9999999999999999999;
  margin-bottom: 100px;
  
  @media (max-width: 720px) {
    justify-content: space-around;
    height: 100px;
  }
`;

export const HamburgerMenu = styled.div`
  cursor: pointer;
  background-color: #000;
  border-radius: 100%;
top: 4.5%;
  padding: 8px;
  position: fixed;
  left: 3%;
  transform: scale(1.25);
  
  div {
    width: 20px;
    height: 3px;
    background-color: #DA1A35;
    margin: 2px 0;
    transition: 0.4s;
  }

  @media (max-width: 720px) {
    order: -1;
  }
`;

export const Logo = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;

`;

export const ModeSymbol = styled.img`
  width: 30px;
  height: 30px; // Adjust size as needed
  
  transition: all 0.3s ease-in-out;

  // Add any additional styles here, such as a glow effect, border, etc.
`;

export const LogoContainer = styled(LinkR)`
   
    //display: flex;
    transition: all 0.5s ease;
    cursor: pointer;
    position: fixed;
    width: 280px;
    height: 55px;
    right: 0;
    //top: 3%;
    left: 5%;
    transform: scale(0.8);
    top: 2.75%;
    //transform: translateX(-50%);
    //margin-left: auto;
    //margin-right: auto;
    //margin: 0 auto;
 
    text-decoration: none;
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    //opacity: ${({ isUtilitiesOpen }) => (isUtilitiesOpen ? '0' : '1')};
    //filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    &:hover  {
        transition: all 0.2s ease-in-out;
       // backdrop-filter: blur(5px);
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));


  }

  @media screen and (max-width: 1024px)  {
  
   // transform: translateX(-50%);
      //transform: translate(-50%, -50%) scale(0.75);
      //margin-top: 7.5%;
     // margin-top: 35px;
      //display: none;
  }

  @media screen and (max-width: 900px)  {
      
      //transform: translate(-50%, -50%) scale(0.55);
      //margin-top: 7.5%;
     // margin-top: 35px;
      //display: none;
  }

    @media screen and (max-width: 1024px)  {
      
        //top: 5%;
        //zoom: 1;
        left: 50%;
        transform: translateX(-50%) scale(.85);
        //margin-top: 7.5%;
       // margin-top: 35px;
        //display: none;
    }
`

export const CenterLogo = styled.img`
width: 89.859px;
height: 116px;
//transform: scale(0.8);
flex-shrink: 0;
cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scale(0.8);

  @media (max-width: 720px) {
    left: 94%;
    top: -15px;
        transform: translateX(-50%) scale(.5);
  }
`;

export const NavItems = styled.div`
  position: fixed;
  right: 7.5%;
  display: flex;
  gap: 20px;

  @media (max-width: 720px) {
    display: none;
  }
`;

export const NavItem = styled.a`
  color: #1D1D1D;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 64.05px; /* 427% */
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    color: #DA1A35; /* Color changes to #DA1A35 on hover */
  }
`;


export const ToggleMode = styled.div`
  width: 25px;
  height: 25px;
  position: fixed;
  right: 3%;
  top: 5%;
  border-radius: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: ${props => props.isDarkMode ? '0 0 10px #FFF' : '0 0 10px #000'};

  img {
    width: 40px;
    height: 40px; // Adjust as needed
  }
`;
