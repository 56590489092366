import React, { useEffect, useState } from 'react';
import { PageBreak } from '../../components/GlobalComponents/GlobalElements';
import pb from '../../media/images/PageBreak.png';
import { story1, story2, story3, story4 } from '../SectioLayout/GeneralInfoSection/Data';
import InfoSection from '../SectioLayout/GeneralInfoSection/GeneralInfoSection';
import { longData2 } from '../SectioLayout/LongPageSection/Data.js';
import LongInfoSection from '../SectioLayout/LongPageSection/Long';
const Story = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const divStyle = {
    paddingTop: windowWidth <= 600 ? '20px' : '100px',
  };
  return (
  <>
        <div  style={divStyle}>
<InfoSection  {...story1} />
        </div>

        <PageBreak src={pb}/>
<InfoSection  {...story2} />
<PageBreak src={pb} style={{ transform: 'scaleX(-1)' }} />
<LongInfoSection {...longData2}/>

<PageBreak src={pb}/>
<InfoSection  {...story3} />
<PageBreak src={pb} style={{ transform: 'scaleX(-1)' }} />
<InfoSection  {...story4} />
<PageBreak src={pb} />

</>
  );
};

export default Story;
