import { AnimatePresence, motion, useAnimation } from "framer-motion";
import React from 'react';
import { IoLogoFacebook, IoLogoInstagram, IoLogoTiktok, IoLogoYoutube } from 'react-icons/io5';
import bird from '../../media/images/BirdSymbolMain.png';
import sun from '../../media/images/SunnyIslandLogo.png';
import {
  BottomLine,
  BrandImage,
  BrandText,
  FeaturedBrand,
  FeaturedBrandContent,
  FeaturedBrandHeader,
  RoundedSocialButtons,
  SidebarContainer,
  SidebarContainerWrap,
  SidebarItem,
  SidebarLinks,
  SidebarMenu,
  SocialIcon,
  SocialMediaWrap,
  Symbol,
  TopLine
} from './SidebarElements';


const Sidebar = ({isFooterVisible, isOpen, toggle, isDarkMode, }) => {

  const controls = useAnimation();
  const featuredBrandControls = useAnimation();
  const featuredBrandHoverVariants = {
    hover: { scale: 1.05, transition: { type: "spring", stiffness: 300 } }
  };

  const itemVariants = {
    closed: { x: -100, opacity: 0, filter: 'blur(15px)' },
    open: { x: 0, opacity: 1, filter: 'blur(0px)', transition: { duration: 0.5 } }
  };

  const sidebarVariants = {
    open: { transition: { staggerChildren: 0.1, delayChildren: 0.2 } },
    closed: {}
  };
 
  // Animation Variants for Symbol
  const symbolVariants = {
    closed: { opacity: 0 },
    open: { opacity: 1, transition: { duration: 0.5 } }
  };
  
    // Animation Variants for FeaturedBrand components
    const featuredBrandItemVariants = {
      closed: { opacity: 0 },
      open: { opacity: 1, transition: { duration: 0.5 } }
    };
  


    return (
<>
{isFooterVisible ? null : (
    
 <>

 <AnimatePresence>
 <SidebarContainerWrap isOpen={isOpen} onClick={toggle} />
<SidebarContainerWrap/>
                    {isOpen && (
                        <SidebarContainer
                            initial="closed"
                            animate="open"
                            exit="closed"
                            variants={sidebarVariants}
                            transition={{ duration: 0.25 }}
                            isOpen={isOpen}
                        >

   

   
           
                <SidebarMenu>
       
          
   
                <Symbol src={bird} variants={symbolVariants} />
                                <SidebarLinks>
                                    <SidebarItem variants={itemVariants} href="/">Home</SidebarItem>
                                    <SidebarItem variants={itemVariants} href="/OurStory">Our Story</SidebarItem>
                                    <SidebarItem variants={itemVariants} href="/Companies">Companies</SidebarItem>
                                    <SidebarItem variants={itemVariants} href="/News">Insider Updates</SidebarItem>
                                    <SidebarItem variants={itemVariants} href="/Contact">Contact</SidebarItem>
                                </SidebarLinks>
                                <FeaturedBrand as={motion.div} whileHover={featuredBrandHoverVariants}>
                  <FeaturedBrandHeader variants={featuredBrandItemVariants}>
                    FEATURED BRAND
                  </FeaturedBrandHeader>
                  <FeaturedBrandContent>
                    <BrandImage src={sun} alt="Brand" variants={featuredBrandItemVariants} />
                    <BrandText variants={featuredBrandItemVariants}>
                      SUNNY ISLAND PEPPER SAUCE
                    </BrandText>
                  </FeaturedBrandContent>
                </FeaturedBrand>
                

            
      
         
          
                  </SidebarMenu>



             
                  <SocialMediaWrap  
                     initial={{ x: -150, opacity: 0}}
                
                //animate={isOpen ? "open" : "closed"}
               //</SidebarMenu> initial={isOpen ? { opacity: 1 } : { opacity: 1 }}
                transition={isOpen ? { staggerChildren: 2, staggerDirection: -1, delay: 0.5} :  {staggerChildren: 0.2, staggerDirection: 1}}
                animate={isOpen ? { x: 0, opacity: 1, filter: "blur(0px)" } :{ x: -150, opacity: 0, filter: "blur(5px)"}}  
           isDarkMode={isDarkMode}
                >
                
                      <RoundedSocialButtons    transition={isOpen ? { staggerChildren: 8, staggerDirection: -1} :  {staggerChildren: 0.2, staggerDirection: 1}}      >
          
          <SocialIcon><IoLogoInstagram /></SocialIcon>
          <SocialIcon><IoLogoFacebook /></SocialIcon>
          <SocialIcon><IoLogoTiktok /></SocialIcon>
          <SocialIcon><IoLogoYoutube /></SocialIcon>


                      </RoundedSocialButtons>
                      
                </SocialMediaWrap>
               
           
            <TopLine/>
            <BottomLine/>
    

        </SidebarContainer>
                    )}
   </AnimatePresence>
   </>
   )}
        </>
        
    );
};

export default Sidebar

