//HERO SECTION DATA

export const news1 = {
    id: 'mental-health-workplace',
    isDarkMode: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Mental Health in the Workplace',
    headline: 'Nurturing Minds, Boosting Productivity',
    description: "Dive into how prioritizing mental health transforms workplace dynamics, enhancing both employee well-being and overall productivity. Discover the transformative power of a supportive work environment.",
    article: 'The Importance of Mental Health in the Workplace',
    img: 'https://ferachogroup.s3.amazonaws.com/images/NewsletterImages/NewsletterArticle1.png',
    topLine2: 'Benefits of Strategic Partnerships for Startups',
    headline2: 'Synergy for Success: Strategic Partnerships',
    description2: "Uncover the secrets of how strategic partnerships can be a game-changer for your startup, propelling your business into new realms of growth and success.",
    article2: 'Strategic Partnerships for Startups: Why are Partnerships Important From Day 1?',
    img2: 'https://ferachogroup.s3.amazonaws.com/images/NewsletterImages/NewsletterArticle2.png',
    divStart: true,
    imgStart: false,
    name: 'mental-health-article',
    alt: 'Mental Health in the Workplace Article Image',
    name2: 'strategic-partnerships-article',
    alt2: 'Strategic Partnerships Article Image',
    buttonLabel: 'Read',
    buttonLabel2: 'Read',
    too: '',
    too2: '',
    primary: true,
};


export const news2 = {
    id: 'employee-mental-health',
    isDarkMode: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Employee Mental Health Programs',
    headline: 'Investing in Mental Wellness',
    description: "Explore the far-reaching benefits of mental health programs in the workplace and how they lead to a thriving, productive work culture.",
    article: 'The Deeper Benefits of Employee Mental Health Programs',
    img: 'https://ferachogroup.s3.amazonaws.com/images/NewsletterImages/NewsletterArticle3.png',
    topLine2: 'Mental Health Programs in Business',
    headline2: 'Blueprint for Workplace Well-being',
    description2: "Delve into a comprehensive guide for integrating effective mental health initiatives in your business, fostering a healthier, more resilient workforce.",
    article2: 'A Blueprint for Workplace Mental Health Programs',
    img2: 'https://ferachogroup.s3.amazonaws.com/images/NewsletterImages/NewsletterArticle4.png',
    divStart: true,
    imgStart: true,
    name: 'employee-wellness-article',
    alt: 'Employee Mental Health Programs Article Image',
    name2: 'business-mental-health-article',
    alt2: 'Mental Health Programs in Business Article Image',
    buttonLabel: 'Read',
    buttonLabel2: 'Read',
    too: '',
    too2: '',
    primary: true,
};


export const news3 = {
    id: 'business-growth-partnerships',
    isDarkMode: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Partnering for Business Growth',
    headline: 'Expanding Horizons Through Partnerships',
    description: "Discover the dynamic world of strategic partnerships and how they can unlock new growth opportunities for your startup, charting a path to unprecedented success.",
    article: 'The Role of Strategic Partnerships in Startup Growth: Leveraging ...',
    img: 'https://ferachogroup.s3.amazonaws.com/images/NewsletterImages/NewsletterArticle5.png',
    topLine2: 'Mental Health as a Business Priority',
    headline2: 'Mental Health: A Core Business Priority',
    description2: "Unearth why mental health is not just a personal concern but a crucial business strategy, and how prioritizing it can revolutionize your organizational culture.",
    article2: 'The Importance of Mental Health Programs in Business',
    img2: 'https://ferachogroup.s3.amazonaws.com/images/NewsletterImages/NewsletterArticle6.png',
    divStart: true,
    imgStart: false,
    name: 'partnership-growth-article',
    alt: 'Business Growth Through Partnerships Article Image',
    name2: 'mental-health-business-article',
    alt2: 'Mental Health as a Business Priority Article Image',
    buttonLabel: 'Read',
    buttonLabel2: 'Read',
    too: '',
    too2: '',
    primary: true,
};




