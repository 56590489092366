export const longData1 = {
  pageBg: "https://ferachogroup.s3.amazonaws.com/images/HeroImages/HeroLongBg.png",
  header: "A Spectrum of Innovation Under One Umbrella",
  description: "Feracho Brand unites a variety of subsidiaries, each excelling in its domain. From the media-rich Feracho Platforms to the compassionate Feracho Anonymous focusing on mental health, our spectrum is as diverse as it is impactful.",
  btnLabel: "EXPLORE",
  textBg: "https://ferachogroup.s3.amazonaws.com/images/HeroImages/HeroLong.png",
  imgStart: true,
};

export const longData2 = {
  pageBg: "https://ferachogroup.s3.amazonaws.com/images/OurStoryImages/StoryLongBg.png",
  header: "Why Support With Feracho Brand?",
  description: "FERACHO Signature is more than a brand; it's a beacon for businesses and individuals alike, inspiring a symphony of taste and entrepreneurial spirit. By intertwining the bold essence of Sunny Island Pepper Sauce with our commitment to community and wellness, we craft a narrative that goes beyond the palate.",
  btnLabel: "EXPLORE",
  textBg: "https://ferachogroup.s3.amazonaws.com/images/OurStoryImages/StoryLong.png",
  imgStart: false,
};
