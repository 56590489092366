
import styled from 'styled-components';

export const Button = styled.button`
display: flex;
width: 221.39px;
height: 48px;
padding: 12px 23.665px 12px 24px;
justify-content: center;
align-items: center;
flex-shrink: 0;
color: #fff;
border-radius: 6px;
background: #DA1A35;
cursor: pointer;
border: none;

//text
color: #FFF;

text-align: center;
font-family: 'Barlow', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 900;
line-height: 24px; /* 150% */


`

export const ButtonLong = styled.button`
display: flex;
width: 221.39px;
height: 48px;
padding: 12px 23.665px 12px 24px;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 6px;
color: #fff;
background: #000;
cursor: pointer;
border: none;

//text
color: #FFF;
text-align: center;
font-family: 'Barlow', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 900;
line-height: 24px; /* 150% */
margin-left: 50%;
transform: translateX(-50%);


`

export const ButtonGrid = styled.button`
display: flex;
width: 221.39px;

border-radius: 6px;
background: #DA1A35;
border: none;
height: 38.406px;
padding: 11px 37.018px 9.123px 11px;
align-items: flex-start;

flex-shrink: 0;
background: #FFF;
cursor: pointer;
position: relative;
margin-left: 0%;

//text
color: #DA1A35;

font-family: 'Barlow', sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 18px; /* 75% */
letter-spacing: 1px;
text-transform: uppercase;

`











export const PageBreak = styled.img`
  display: flex;
  width: 100vw;
  height: 50px; // Fixed height
  object-fit: cover; // This will ensure the image covers the area without distortion
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #FFF;
  position: relative;
  z-index: 999999;
`;

export const PageBreakText = styled.div`
 
  flex-shrink: 0;
  background: #000;
  display: flex;
  width: 100vw;
  height: 25px;
  flex-direction: column;
  justify-content: center;
  color: #FFF;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 21px;
`;