import styled from "styled-components";

export const InfoContainer = styled.div`
    position: relative;
    color: #fff;
   
    overflow: hidden;
    //width: 1920px;
    height: 520px;
    //top: 50px;
    z-index:  9;
    //bottom: 50px;
    background: ${({isDarkMode}) => (isDarkMode ? '#F0F8FF' : '#010606')};
    padding: 0; // Adjust padding as needed

    @media screen and (max-width: 640px) {
        height: 100%; // Adjust for mobile layout
        margin-top: 75px;
    }
`;


export const InfoContainer1 = styled.div`
    color: #fff;
    background: ${({isDarkMode}) => (isDarkMode ? '#F0F8FF' : '#660099')};

    @media screen and (max-width: 768px) {
        padding: 150px 0;
    }
`;

export const InfoWrapper = styled.div`
    display: grid;
  
   // margin-top: 65px;
    //width: 100vw;
   // max-width: 500px;
    margin-right: auto;
    margin-left: auto;

`;

export const InfoRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr; // Each column takes up half of the space
    align-items: end;
    grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr; // Switch to single column layout
        grid-template-areas: 
            'col1' 
            'col2';
        align-items: center; // Center contents vertically
        justify-items: center; // Center contents horizontally
    }
`;



export const Column1 = styled.div`
    padding: 0 15px;
    grid-area: col1;
`;

export const Column2 = styled.div`
    padding: 0 15px;
    grid-area: col2;
    position: relative;
    right: 5%;
    bottom: -75px;
`;




export const TextWrapper = styled.div`
max-width: 600px;
margin-top: 100px;
//padding-top: 50px;
position: relative;
right: ${({ imgStart }) => (imgStart ? '0' : '0')}; // Adjust based on imgStart
    left: ${({ imgStart }) => (imgStart ? '0' : '10%')}; // Adjust based on imgStart

  padding-bottom: 0px; // Adjust this value to ensure even spacing
  text-align: center; // Center the text

`;

export const TopLine = styled.p`

    color: ${({isDarkMode}) => (isDarkMode ? '#660099' : '#fff')};
  color: #FFF;
  margin-left: 50%;
    transform: translateX(-50%);
text-align: center;
font-family: 'Barlow', sans-serif;
font-size: 15px;
font-style: normal;
font-weight: 900;
line-height: 64.05px; /* 427% */
letter-spacing: 2px;
display: flex;
width: 500px;
height: 10px;
flex-direction: column;
justify-content: center;
flex-shrink: 0;
text-transform: uppercase;
//margin-bottom: -25px;
   // margin-bottom: -20px; // Ensure even spacing
`;

export const Heading = styled.h1`
display: flex;
width: 600px;
height: 100px;
flex-direction: column;
justify-content: center;
flex-shrink: 0;
color: #FFF;
text-align: center;
font-family: 'Inter', sans-serif;
font-size: 51.734px;
font-style: normal;
font-weight: 700;
line-height: 45px; /* 123.4% */
letter-spacing: -0.56px;

    color: ${({isDarkMode}) => (isDarkMode ? '#660099' : '#fff')};
   // margin-bottom: -10px; // Ensure even spacing

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const Subtitle = styled.p`

    margin-bottom: 35px;
    margin-left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    line-height: 24px;
    color: #FFF;
    display: flex;
    width: 550px;
    height: 127px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    text-align: center;
    font-family: 'Barlow', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 100;
    line-height: 25px; /* 133.333% */
    letter-spacing: 2px;
    text-transform: uppercase;
    color: ${({isDarkMode}) => (isDarkMode ? '#010606' : '#fff')};
`;

export const BtnWrap = styled.div`
     display: flex;
     justify-content: center; // Center the button

    @media screen and (max-width: 640px) {
        align-items: center;
		
		display:block;
			
		margin: auto;
    }
    
`
export const ImgWrap = styled.div`
    height: 100%;
    max-height: 400px;
    max-width: 700px;
    position: relative;
`;

export const Img = styled.img`
    max-width: 50vw;
    //height: 40x;
    
    width: auto;
    position: absolute;
    
    bottom: 0px;
    left: ${({ imgStart }) => (imgStart ? '10%' : '0')}; // Adjust based on imgStart
    right: ${({ imgStart }) => (imgStart ? '0' : '10%')}; // Adjust based on imgStart

    @media screen and (max-width: 768px) {
        position: static;
        width: 100%;
        transform: scale(2);
    }
`;


export const Img1 = styled.img`
    width: 100%;
    height: 100%;
    
    margin: 0 0 10px 0;
    padding-right: 0;
`;