import React from 'react';
import { MentalContainer, MentalTitle } from './MentalElements';

const Mental = () => {
  return (
    <MentalContainer>
      <MentalTitle>Mental Page</MentalTitle>
      {/* Add more content here */}
    </MentalContainer>
  );
};

export default Mental;
