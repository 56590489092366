export const contactGrid1 = {
    id: 'our-story',
    isDarkMode: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Our Story',
    description: "Check out what the Feracho Brand is about, our mission goals, and how you can become a part of the family!",
    img: 'https://ferachogroup.s3.amazonaws.com/images/ContactImages/ContactNav1.png',
    topLine2: '',
    headline2: 'Feracho Signature',
    description2: "Explore Our Family of Brands. From spicy startups to connecting and establishing your very own brand with us!",
    img2: 'https://ferachogroup.s3.amazonaws.com/images/ContactImages/ContactNav2.png',
    buttonLabel: '',
    buttonLabel2: '',
    article: "",
    article2: "",

    divStart: true,
    imgStart: false,
    name: 'our-story-image',
    alt: 'Our Story Image',
    name2: 'feracho-signature-image',
    alt2: 'Feracho Signature Image',
    too: '',
    too2: '',
    primary: true,
};

export const contactGrid2 = {
    id: 'feracho-newsletter',
    isDarkMode: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'The FERACHO newsletter',
    description: "Keep up to date with the latest and most relevant FERACHO related news here!",
    img: 'https://ferachogroup.s3.amazonaws.com/images/ContactImages/ContactNav3.png',
    topLine2: '',
    headline2: 'Companies & Partnerships',
    description2: "Explore Our Family of Brands. From spicy startups to connecting and establishing your very own brand with us!",
    img2: 'https://ferachogroup.s3.amazonaws.com/images/ContactImages/ContactNav4.png',
    buttonLabel: '',
    buttonLabel2: '',
    article: "",
    article2: "",
    divStart: true,
    imgStart: false,
    name: 'feracho-newsletter-image',
    alt: 'FERACHO Newsletter Image',
    name2: 'companies-partnerships-image',
    alt2: 'Companies & Partnerships Image',
    too: '',
    too2: '',
    primary: true,
};
