import styled from 'styled-components';
export const InfoContainer = styled.div`
    position: relative;
    color: #fff;
    //overflow: hidden;
    //width: 1920px;
    //height: 520px;
    //top: 50px;
    z-index: 999999;
    //bottom: 50px;
    background: ${({isDarkMode}) => (isDarkMode ? '#F0F8FF' : '#010606')};
    padding: 0; // Adjust padding as needed

    @media screen and (max-width: 640px) {
        height: auto; // Adjust for mobile layout
        margin-top: 75px;
    }
`;


export const InfoContainer1 = styled.div`
    color: #fff;
    background: ${({isDarkMode}) => (isDarkMode ? '#F0F8FF' : '#660099')};

    @media screen and (max-width: 768px) {
        padding: 150px 0;
    }
`;

export const InfoWrapper = styled.div`
    display: grid;
  
   // margin-top: 65px;
    width: 100vw;
   // max-width: 500px;
    margin-right: auto;
    margin-left: auto;

`;
export const MainSectionContainer = styled.div`
    position: relative;
    width: 1550px;
    height: 800px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    background: ${({ isDarkMode }) => (isDarkMode ? '#010606' : '#F0F8FF')}; // Adjust color based on isDarkMode
`;

export const LeftDiv = styled.div`
    display: inline-flex;
    padding: 24px 143.264px 65.258px 10px;
    align-items: center;
    background: #FFF; // This can be dynamic based on props if needed
    position: absolute;
    left: 0;
`;

export const RightImage = styled.img`
    width: 1300px;
    height: 800px;
    position: absolute;
    right: 0;
`;

export const OverlappingText = styled.div`
    display: flex;
    width: 320px;
    height: 130px;
    flex-direction: column;
    justify-content: center;
    color: ${({ isDarkMode }) => (isDarkMode ? '#DA1A35' : '#1A1A1A')}; // Adjust color based on isDarkMode
    position: absolute;
    left: 10px; // Adjust as needed
`;

export const TopText = styled.p`
    color: #000; // Top line text color
    font-family: Inter;
    font-size: 50.859px;
    font-weight: 700;
    line-height: 63.28px;
    letter-spacing: -0.64px;
`;

export const BottomText = styled.p`
    color: #DA1A35; // Second line text color
    font-family: Inter;
    font-size: 50.859px;
    font-weight: 700;
    line-height: 63.28px;
    letter-spacing: -0.64px;
`;

export const FooterText = styled.div`
    display: flex;
    width: 1320px;
    height: 154px;
    font-family: 'Barlow', sans-serif;
    
`