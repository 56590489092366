import React from 'react';
import { ButtonGrid } from '../../../components/GlobalComponents/GlobalElements';
import {
    BtnWrap,
    Column,
    Column2,
    Heading,
    Img,
    ImgWrap,
    NewsInfoContainer,
    NewsInfoRow,
    NewsInfoWrapper,
    Subtitle,
    TextWrapper,
    TopLine
} from './NewsElements';

function NewlineText(props) {
    const text = props.text;
    return text.split('\n').map(str => <p>{str}</p>);
}

const NewsArticleSection = ({
    isDarkMode,
    img, alt, topLine, headline, description, buttonLabel, to,
    img2, alt2, topLine2, headline2, description2, buttonLabel2, to2
}) => {
    return (
        <NewsInfoContainer isDarkMode={isDarkMode}>
            <NewsInfoWrapper>
                <NewsInfoRow>
                    <Column>
                        <TextWrapper>
                            <ImgWrap>
                                <Img src={img} alt={alt} />
                            </ImgWrap>
                         
                            <Heading isDarkMode={isDarkMode}>{headline}</Heading>
                            <TopLine isDarkMode={isDarkMode}>{topLine}</TopLine>
                            <Subtitle isDarkMode={isDarkMode}><NewlineText text={description}/></Subtitle>
                            <BtnWrap>
                                <ButtonGrid isDarkMode={isDarkMode} to={to}>{buttonLabel}</ButtonGrid>
                            </BtnWrap>
                        </TextWrapper>
                    </Column>

                    <Column2>
                        <TextWrapper>
                            <ImgWrap>
                                <Img src={img2} alt={alt2} />
                            </ImgWrap>
                          
                            <Heading isDarkMode={isDarkMode}>{headline2}</Heading>
                             <TopLine isDarkMode={isDarkMode}>{topLine2}</TopLine>
                            <Subtitle isDarkMode={isDarkMode}><NewlineText text={description2}/></Subtitle>
                            <BtnWrap>
                                <ButtonGrid isDarkMode={isDarkMode} to={to2}>{buttonLabel2}</ButtonGrid>
                            </BtnWrap>
                        </TextWrapper>
                    </Column2>
                </NewsInfoRow>
            </NewsInfoWrapper>
        </NewsInfoContainer>
    );
};

export default NewsArticleSection;
