import React, { useEffect, useState } from 'react';
import ContactGridSection from '../SectioLayout/ContactLayoutGrid/Contact';
import InfoSection from '../SectioLayout/GeneralInfoSection/GeneralInfoSection';

import { contact1, contact2 } from '../SectioLayout/GeneralInfoSection/Data';

import { PageBreak } from '../../components/GlobalComponents/GlobalElements';
import pb from '../../media/images/PageBreak.png';
import { contactGrid1, contactGrid2 } from '../SectioLayout/ContactLayoutGrid/Data';
const Contact = ({isDarkMode}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const divStyle = {
    paddingTop: windowWidth <= 600 ? '20px' : '100px',
  };
  return (
  <>
        <div  style={divStyle}>
        <InfoSection  {...contact1} />
        </div>
        <PageBreak src={pb}/>
 
    <ContactGridSection  {...contactGrid1}/>
    <ContactGridSection  {...contactGrid2}/>
    <ContactGridSection/>
    <PageBreak src={pb}/>
    <InfoSection  {...contact2}/>
    <PageBreak src={pb}/>
  </>
  );
};

export default Contact;
