import React, { useEffect, useState } from 'react';

import { PageBreak, PageBreakText } from '../../components/GlobalComponents/GlobalElements';
import pb from '../../media/images/PageBreak.svg';
import { hero1, hero2, hero3 } from '../SectioLayout/GeneralInfoSection/Data';
import InfoSection from '../SectioLayout/GeneralInfoSection/GeneralInfoSection';
import { longData1 } from '../SectioLayout/LongPageSection/Data.js';
import LongInfoSection from '../SectioLayout/LongPageSection/Long';
const Hero = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const divStyle = {
    paddingTop: windowWidth <= 600 ? '20px' : '100px',
  };
  return (
  <>
        <div  style={divStyle}>
        <PageBreakText>
            Every bottle of sauce has to say something good!
            </PageBreakText>
        <InfoSection   {...hero1} />
        </div>
        <PageBreak src={pb}/>
        <PageBreakText>
  
    </PageBreakText>
        <InfoSection  {...hero2} />
        <PageBreak src={pb} style={{ transform: 'scaleX(-1)' }} />
      
          <LongInfoSection  {...longData1}/>
        <PageBreak src={pb}/>
            <PageBreakText>
             Mental Health care is Wealth! Explore now to read up on the cause and how to help!
            </PageBreakText>
        <InfoSection  {...hero3} />
        <PageBreak src={pb} style={{ transform: 'scaleX(-1)' }} />
  </>
  );
};

export default Hero;
