import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const FooterContainer = styled.footer`
  width: 100vw;
  height: 450px;
  display: flex;
  justify-content: center;
  //     u u j
  background: linear-gradient(180deg, #FFF 0%, #DA1A35 100%);
`;

export const Column = styled.div`
  width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;

`;

export const LogoContainer = styled(Link)`
   
    //display: flex;
    transition: all 0.5s ease;
    cursor: pointer;
    position: fixed;
    width: 280px;
    height: 55px;
    right: 0;
    //top: 3%;
    left: 5%;
    transform: scale(0.8);
    top: 2.75%;
    //transform: translateX(-50%);
    //margin-left: auto;
    //margin-right: auto;
    //margin: 0 auto;
 
    text-decoration: none;
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    //opacity: ${({ isUtilitiesOpen }) => (isUtilitiesOpen ? '0' : '1')};
    //filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    &:hover  {
        transition: all 0.2s ease-in-out;
       // backdrop-filter: blur(5px);
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));


  }

  @media screen and (max-width: 1024px)  {
  
   // transform: translateX(-50%);
      //transform: translate(-50%, -50%) scale(0.75);
      //margin-top: 7.5%;
     // margin-top: 35px;
      //display: none;
  }

  @media screen and (max-width: 900px)  {
      
      //transform: translate(-50%, -50%) scale(0.55);
      //margin-top: 7.5%;
     // margin-top: 35px;
      //display: none;
  }

    @media screen and (max-width: 1024px)  {
      
        //top: 5%;
        //zoom: 1;
        left: 50%;
        transform: translateX(-50%) scale(.85);
        //margin-top: 7.5%;
       // margin-top: 35px;
        //display: none;
    }
`
export const NewsletterHeader = styled.div`
  display: flex;
  width: 412px;
  height: 27px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 27px; /* 135% */
  text-transform: uppercase;
`;
export const ColumnsDiv = styled.div`
display: flex;
flex-direction: row;
margin-top: 20%;
margin-left: 20%;


`
export const SubColumn = styled.div`
  /* Styles for your sub-columns */
`;

export const SubColumnLink = styled(Link)`
  display: flex;
  width: 250px;
  height: 30px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #000;

  font-family: 'Avenir', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none; // Removes underline from links
white-space: nowrap;
  &:hover {
    color: #DA1A35;
  }
`;
export const InputForm = styled.form`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 19%;
  margin-bottom: 5%;
  border: none;
  input[type="email"] {
    display: inline-flex;
    width: 360px;
    height: 25px;
    padding: 7px 3px 6px 5px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 15px;
    border-bottom: 1px solid #A0A09F;
    background: rgba(255, 255, 255, 0.70);
    color: #605E5E;
    font-family: Arial;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
  }

  button[type="submit"] {
    width: 121px;
    height: 39px;
    flex-shrink: 0;
    border-radius: 15px;
    text-transform: uppercase;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #FFF;
    border: none;
    text-align: center;
    font-family: 'Barlow', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 24px;
    padding: 7px 3px 6px 27.5px;
    background: #000;
    color: white; /* Assuming you want the text in white */
   /* Add font styling for the button text if needed */
  }
`;

export const Symbol = styled.img`
  width: 275px;
  height: 375px;
  /* Additional styles */
`;

export const SocialIcon = styled.div`
  color: #000; // Default color
  margin: 0 10px; // Spacing between icons
  font-size: 24px; // Icon size
cursor: pointer;
  &:hover {
    color: #DA135A;
    transform: scale(1.1); // Example animation
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // Additional styling as needed
`;

export const Quote = styled.p`
  font-size: small;
  display: flex;
width: 421px;
height: 17px;
flex-direction: column;
transform: translateX(85px);
justify-content: left;
flex-shrink: 0;
color: #1A1A1A;

font-family: 'Inter', sans-serif;

font-size: small;
font-style: normal;
font-weight: 600;
line-height: 32px; /* 213.333% */
color: #1A1A1A;
font-family: 'Inter', sans-serif;
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: 32px;
margin-top: 10%;
  /* Additional styles */
`;

export const EmphasizedQuotePart = styled.span`
  display: flex;
  width: 297px;
  height: 23px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #000;
  text-align: right;
margin-top: -17px;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 3.4px;
`;



export const FeaturedBrand = styled.div`
  width: 260px;
  height: 329px;
margin-right: 20%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BrandHeader = styled.div`
  display: flex;
  width: 256px;
  height: 27px;
  margin-top: 40px;
  white-space: nowrap;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #DA1A35;
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 900;
  line-height: 27px;
  text-transform: uppercase;
`;

export const BrandSubHeader = styled.div`
width: 244px;
height: 43px;
flex-shrink: 0;
color: #F8F03A;

text-align: center;
font-family: 'Inter', sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 900;
line-height: 20px; /* 100% */
`;

export const BrandDescription = styled.div`
  width: 247px;
  height: 41px;
  flex-shrink: 0;
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.5px;
`;

export const BrandLogo = styled.img`
  width: 175px;
  height: 211px;
  transform: scale(0.85);
  flex-shrink: 0;
  /* Add styles for the logo here, e.g., background-image if it's an image */
`;