import styled from 'styled-components';

export const NewsInfoContainer = styled.div`
  color: #fff;
  background: ${({ isDarkMode }) => (isDarkMode ? '#f9f9f9' : '#010606')};

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

export const NewsInfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 900px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  //padding: 0 24px;
  justify-content: center;
`;

export const NewsInfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-gap: 50px;
  grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
  }
`;

export const Column = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  max-width: 500px;
  padding-top: 0;
  padding-bottom: 60px;
`;

export const TopLine = styled.p`
display: flex;
width: 400px;
height: 20px;
flex-direction: column;
justify-content: center;
flex-shrink: 0;
color: #737373;

font-family: 'Barlow', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 19.5px; /* 108.333% */
`;

export const Heading = styled.h1`
 display: flex;
width: 523px;
height: 27px;
flex-direction: column;
justify-content: center;
flex-shrink: 0;


font-family: 'Inter', sans-serif;
font-size: 21.938px;
font-style: normal;
font-weight: 700;
line-height: 28.08px; /* 128% */
letter-spacing: -0.24px;
  color: ${({ isDarkMode }) => (isDarkMode ? '#f7f8fa' : '#010606')};
  color: #DA1A35;
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
display: flex;
width: 387px;

flex-direction: column;
justify-content: center;
flex-shrink: 0;
color: #737373;

font-family: 'Barlow', sans-serif;
font-size: 13.5px;
font-style: normal;
font-weight: 500;
line-height: 19.5px;
  color: ${({ isDarkMode }) => (isDarkMode ? '#010606' : '#fff')};
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: left;
`;

export const ImgWrap = styled.div`
  width: 500px;
  height: 500px;
 /// height: 100%;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;
