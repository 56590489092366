import React, { useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
//Pages
import Companies from "./pages/Companies/Companies";
import Contact from "./pages/Contact/Contact";
import Signature from "./pages/FerachoSignature/Signature";
import Hero from "./pages/HeroPage/Hero";
import MentalHealth from "./pages/MentalHealth/Mental";
import News from "./pages/Newsletter/News";
import Story from "./pages/OurStory/Story";

//Components
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Sidebar from "./components/Sidebar/Sidebar";
const App = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Router>
    <Navbar isOpen={isOpen} setIsOpen={setIsOpen} isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode}/>
    <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode}/>
      <Routes>
        <Route path="/" element={<Hero isDarkMode={isDarkMode}  />} />
        <Route path="/OurStory" element={<Story isDarkMode={isDarkMode}  />} />
        <Route path="/Companies" element={<Companies isDarkMode={isDarkMode}  />} />
        <Route path="/Signature" element={<Signature isDarkMode={isDarkMode}  />} />
        <Route path="/News" element={<News isDarkMode={isDarkMode}  />} />
        <Route path="/Contact" element={<Contact isDarkMode={isDarkMode}  />} />
        <Route path="/MentalHealth" element={<MentalHealth isDarkMode={isDarkMode}  />} />
      </Routes>
      <Footer  isDarkMode={isDarkMode} />
    </Router>
  );
};

export default App;
