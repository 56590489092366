import { motion } from 'framer-motion';
import { BiNetworkChart } from "react-icons/bi";
import { FaClipboardList, FaDiscord, FaTimes } from 'react-icons/fa';

import { FaXTwitter } from 'react-icons/fa6';
import { HiDocumentText } from 'react-icons/hi';
import { MdVerified } from 'react-icons/md';
import { RiGitRepositoryPrivateFill } from 'react-icons/ri';
import styled from 'styled-components';



///
/// Sidebar Elements
///




export const SidebarContainerWrap = styled.div`
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
    position: fixed;
    z-index: 999;
    top: -10px;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    transition: opacity 0.5s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
    backdrop-filter: blur(5px);
`;

export const SidebarContainer = styled(motion.div)` 
    overflow: hidden;
    position: fixed;
    width: 20vw; /* Set your desired width */
    min-width: 375px; 
    height: 100%;
   // border-radius: 30px;
    z-index: -1;
    z-index: ${({ isOpen }) => (isOpen ? '9999999' : '0')};
    left: ${({ isOpen }) => (isOpen ? '0' : '-200%')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    margin-left: 0%;
    background-repeat: repeat;
    box-shadow: ${({ isOpen }) =>
        isOpen ? '0 0 60px rgba(0, 0, 0, 0.5)' : 'none'}; /* 3x larger blur radius */
    @media screen and (max-width: 1024px)  {
      width: 100vw;
      min-width: 100%; 
    }

`;



// Individual Strip
export const Strip = styled.div`
  height: 2px;
  border-radius: 2px;
  background: rgb(56, 56, 56);
  margin: 5px;
  transition: all 0.55s cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 30px;




`;

// Burger Container
export const Burger = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 360px;
  position: fixed;
    top: 5%;
  //padding: 8px;
  background: white;
  box-shadow: rgba(136, 165, 191, 0.48) 3px 1px 8px 0px, rgba(255, 255, 255, 0.8) -3px -1px 8px 0px;
  cursor: pointer;
  display: flex;
  
  justify-content: center;
  align-items: center;
  //padding: 16px;
  transition: all 0.5s ease;

  display: flex;
    position: fixed;
    opacity: 1;
    right: 3%;
    top: 5%;
  ${Strip} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      filter: drop-shadow(2px 2px 2px white);
    }

  & :hover  {
    ${Strip} {
      &:nth-child(1) {
        transform: translateY(7.5px) scale(0.5);
       // width: 25px;
      }

    &:nth-child(2) {
       opacity: 0;
    }
    &:nth-child(3) {
      transform: translateY(-7.5px) scale(0.5);
       // width: 25px;
    }
}
    }
    @media screen and (max-width: 768px)  {
       top: 5%;
       transform: scale(1);
       right: 5%;
    }

    

`;

// Burger Strip
export const BurgerStrip = styled.div`
  transition: all 0.5s ease;

`;





// Hover Effect for Burger Strip
BurgerStrip.div = styled.div`
  margin: 10px auto;
`;

// Hover Effect - First Child
export const HoverEffectFirstChild = styled(Strip)`
  transform: translateY(13px);
  width: 25px;
  opacity: 0;

  ${Burger}:hover & {
    opacity: 1;
  }
`;

// Hover Effect - Second Child
export const HoverEffectSecondChild = styled(Strip)`
  opacity: 0;
`;

// Hover Effect - Last Child
export const HoverEffectLastChild = styled(Strip)`
  transform: translateY(-13px);
  width: 25px;
  opacity: 0;

  ${Burger}:hover & {
    opacity: 1;
  }
`;




// COLORS
const softOrange = '#F4A259';
const tomatoRed = '#F25C66';
const mediumBlue = '#1E272D';

export const Body = styled.body`
  background-color: ${mediumBlue};
`;

export const CloseContainer = styled.div`

    width: 48px;
    height: 48px;
    position: absolute;
    top: 1.2rem;
    right: 2.5rem;
    background: transparent;
    @media screen and (max-width: 870px) {
          //transform: scale(0.7);
          zoom: 0.75;
          right: 1.5rem;
          top: 0.75rem;
 
    }


`;

export const LeftRight = styled.div`
  height: 4px;
  width: 50px;
  position: absolute;
  margin-top: 24px;

  background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    cursor: pointer;

  border-radius: 2px;
  transform: rotate(45deg);
  transition: all 0.3s ease-in;
`;

export const RightLeft = styled.div`
  height: 4px;
  width: 50px;
  position: absolute;
  margin-top: 24px;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    cursor: pointer;

  border-radius: 2px;
  transform: rotate(-45deg);
  transition: all 0.3s ease-in;
`;

export const Label = styled.label`
 color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  font-family: Helvetica, Arial, sans-serif;
  font-size: 0.6em;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all 0.3s ease-in;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
`;

export const Close = styled.div`
  margin: 60px 0 0 5px;
  position: absolute;
`;

export const CloseContainerHover = styled(CloseContainer)`
  &:hover ${LeftRight} {
    transform: rotate(-45deg);
    background-color: ${tomatoRed};
  }

  &:hover ${RightLeft} {
    transform: rotate(45deg);
    background-color: ${tomatoRed};
  }

  &:hover ${Label} {
    opacity: 1;
  }
`;


export const CloseIcon = styled(FaTimes)`
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    cursor: pointer;
    &:hover  {
    transition: all 0.2s ease-in-out;

    filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 0px white)' : 'drop-shadow(0 0 0px black)')};
    //backdrop-filter: blur(5px);
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};


  
  }
  @media screen and (max-width: 870px) {
          //transform: scale(0.7);
          zoom: 0.65;
          margin-bottom: 20px;
 
    }
  
`;

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;

    right: 1.5rem;
    background: transparent;
    font-size: 3rem;
    cursor: pointer;
    outline: none;
`;


export const SidebarMenu = styled.div`

   position: absolute;
   top: 10%;
   left: 50%;
   transform: translate(-50%);
   transition: 0.3s ease-in-out;
  // transform-origin: left;



  @media screen and (max-width: 900px) {
 
  }
  @media screen and (max-width: 800px) {

  }
  @media screen and (max-width: 700px) {

  }
  @media screen and (max-width: 600px) {
  
  }
  @media screen and (max-width: 500px) {
    //transform: translate(-50%) matrix(1.5, 0, 0, 1.25, 0, 0);
    transform: translate(-50%) scale(1.25);
    top: 22.5%;
    left: 50%;
  }
   /*
    @media screen and (max-width: 1100px) {
          transform: scale(0.8);
          //left: 0%;
       
    }
    @media screen and (max-width: 870px) {
      //position: relative;
      left: 50%;
          transform: scale(0.6) translateX(-50%);
         // margin-left: 0%;
        
    }
    @media screen and (max-width: 768px) {
      //position: relative;
          //transform: scale(0.35);
          transform: scale(0.35) translateX(-10%);
          top: 15%;
         // left: -30%;
        
    }
    */


`;

export const SidebarLink =  styled(motion.LinkS)`
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.5rem;
    background-color: #000;
    color: #000;
    width: 300px;
    height: 50px;
    border-radius: 50px;
    box-sizing: border-box;
    box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.2);
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    z-index: 99999;
    white-space: nowrap;

    /* text edit */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 1.35rem;
    line-height: 28px;
    letter-spacing: -1px;
    text-transform: uppercase;
  /* or 81% */




  position: absolute;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  background-color: ${({ isDarkMode }) => (isDarkMode ? `#fff` : `#000`)};

    &:hover {
        color: #660099;
        transition: 0.2 ease-in-out;
    }
`;
export const LineBreak = styled.hr`
`

export const SideBtnWrap = styled(motion.div)`
    display: flex;
  
    text-decoration: none;
    white-space: nowrap;
 

    filter: drop-shadow(0 0 0px white);

    &:hover {
       // zoom: 1.02;
       transform: scale(1.02);
       transition: 0.2 ease-in-out;
      // filter: drop-shadow(0 10px 20px white);
    }

 

  
`;

export const SideBtnImg = styled.img`
    width: 150px;
    height: 150px;
    position: absolute;
    gap: 100px;
    cursor: pointer;
   // margin-top: 50px;

`



export const Dao = styled(BiNetworkChart)`
  margin-right: 25px;
  font-size: 2rem;
    top: 7px;
    transition: all 0.2s ease-in-out;
    padding: 6px 6px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
    box-sizing: border-box;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    @media screen and (max-width: 870px) {
  //top: 45px;
           }



`

export const Discord = styled(FaDiscord)`
    margin-right: 25px;
    font-size: 2rem;
    color: #fff;
    top: 9px;
    transition: all 0.2s ease-in-out;
    padding: 6px 6px;
    position: relative;
    border-radius: 100%;
    box-sizing: border-box;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
 
    @media screen and (max-width: 870px) {
 // top: 45px;
           }
`

export const X = styled(FaXTwitter)`
  margin-right: 25px;
  font-size: 2rem;
    top: 7px;
    padding: 6px 6px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.2s ease-in-out;
    border-radius: 100%;
    box-sizing: border-box;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    @media screen and (max-width: 870px) {
  //top: 45px;
           }


`
export const Privacy = styled(RiGitRepositoryPrivateFill)`
    transition: all 0.2s ease-in-out;
      font-size: 2rem;
    color: #fff;
    top: 9px;
    white-space: nowrap;
    padding: 12px 12px;
    position: relative;
    border-radius: 100%;
    box-sizing: border-box;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
 
    @media screen and (max-width: 870px) {
  top: 15px;
  font-size: 2.5rem;
           }

`

export const Terms = styled(HiDocumentText)`
    transition: all 0.2s ease-in-out;
      font-size: 2rem;
    color: #fff;
    white-space: nowrap;
    top: 9px;
    padding: 12px 12px;
    position: relative;
    border-radius: 100%;
    box-sizing: border-box;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    @media screen and (max-width: 870px) {
  top: 15px;
  font-size: 2.5rem;
           }

`


export const Verified = styled(MdVerified)`
    font-size: 24px; 
    color: #68ceff;
    

`
export const Whitelist = styled(FaClipboardList)`
  margin-right: 25px;
  font-size: 2rem;
    top: 7px;
    padding: 6px 6px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    @media screen and (max-width: 870px) {
  //top: 45px;
           }
  
`
export const SidebarImgWrap = styled.div`


`
export const SidebarRoute =  styled(motion.a)`
    border-radius: 50px;
    width: 100%;
    height: 100%;
    font-size: 1.5rem;
    font-weight: 900;
    word-spacing: 5px;
    text-transform: uppercase;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
    white-space: nowrap;
    padding: ${({big}) => (big ? '10px 40px' : '10px 15px')} ;
    color: #000;
    letter-spacing: -2px;
    cursor: pointer;
   // transform: matrix(1.2, 0, 0, 1, 0, 0);
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    
    margin-top: 5px;
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    //background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};


    &:hover  {
    transition: all 0.2s ease-in-out;
    //backdrop-filter: blur(5px);
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};


    ${Whitelist} {
      /* styles for the container when button is hovered */
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  
    filter: drop-shadow(2px 2px 2px white);
    }
    ${X}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  
    filter: drop-shadow(2px 2px 2px white);
    }

    ${Discord} {
      /* styles for the container when button is hovered */
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    
      color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    
      filter: drop-shadow(2px 2px 2px white);
    }

    ${Dao} {
      /* styles for the container when button is hovered */
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  
    filter: drop-shadow(2px 2px 2px white);
    }

    
  
  }
  @media screen and (max-width: 500px) {
    margin-top: -10px;
    transform:" matrix(1.2, 0, 0, 1, 0, 0) "scale(0.85);
    }
`;




export const Symbol = styled(motion.img)`
  width: 200px;
  height: 200px;
  border-radius: 80%;
  margin-left: 50%;
  margin-top: 15%;
  transform: translateX(-50%);

  background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
  // Add more styles or background image for the symbol
`;

export const SidebarLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SidebarItem = styled(motion.a)`
    display: flex;
    width: 215px;
    height: 27px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #FFF;
    text-decoration: none;
    //font-family: "inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px; /* 135% */
    text-transform: uppercase;

    &:hover {
    color: #DA1A35; // Change to pink color on hover
  }
`;

export const FeaturedBrand = styled(motion.div)`
  // Add styles for the featured brand section
`;

export const FeaturedBrandHeader = styled(motion.h2)`
  color: #DA1A35;
  // Additional styling for the header
`;

export const FeaturedBrandContent = styled(motion.div)`
  display: flex;
  align-items: center;
  flex-direction: column; 
  
  // Additional styling
`;

export const BrandImage = styled(motion.img)`
width: 89.859px;
height: 116px;
margin-top: -25px;
margin-left: 45%;
  transform: translateX(-50%);
`;

export const BrandText = styled(motion.p)`
  // Styles for the text
  display: flex;
width: 151px;
height: 34px;
flex-direction: column;
justify-content: center;
flex-shrink: 0;
color: #FFF;

font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 18px; /* 90% */
text-transform: uppercase;
`;

export const BottomLeftContainer = styled.div`
  position: fixed;
  left: 5%;
  pointer-events: all;
  cursor: pointer;
  bottom: 5%;
  width: 100%;
  padding: 0;
  display: inline-flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  @media screen and (max-width: 870px) {
          bottom: 20%;
    }
`;

export const FullWidthText = styled.p`
  white-space: nowrap;
  position: relative;
  top: 10px;
  flex: 1 1 0%;
  font-size: 12px;
  line-height: 1.5em;
  color: black;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  & b {
    font-weight: bold;
  }
  @media screen and (max-width: 870px) {
          //transform: scale(0.7);
          zoom: 0.85;
    }
`;

export const CenteredTitle = styled.p`
  font-family: 'Antonio', sans-serif;
  //flex: 1 1 0%;
  position: absolute;

  font-size: 16px;
  font-weight: 700;
  line-height: 1em;
  text-align: center;
  color: black;
  letter-spacing: -0.5px;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5%;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  @media screen and (max-width: 870px) {
          left: 75%;
          bottom: 20%;
          zoom: 0.75;
    }
`;

export const BottomRightContainer = styled.div`
  position: absolute;
  bottom: 40px;
  right: 40px;
  @media screen and (max-width: 870px) {
          right: 0px;
          left: 50%;
          transform: translateX(-50%);
          bottom: 35px;
          width: 205px;
    }
`;



export const BottomLink = styled.a`
  flex: 1 1 0%;
  font-size: 12px;
  line-height: 1em;
  text-align: right;
  color: black;
  text-decoration: none;
  width: 100%;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  &:hover  {
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);
   // color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};


    ${Privacy} {
      /* styles for the container when button is hovered */
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    
      color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    
      filter: drop-shadow(2px 2px 2px white);
    }
    ${Terms}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    
     color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
      filter: drop-shadow(2px 2px 2px white);
    }

  
  }
  @media screen and (max-width: 870px) {
  top: 45px;
  font-size: 10px;
           }
`;


export const LeftSpacer = styled.div`
   left: 50%;
  transform: translateX(-50%);
  position: absolute;
  
`;


export const SocialMediaWrap = styled(motion.div)`
    position: absolute;

    width: 100%;

    bottom: 20px;
 
    //adisplay: flex;

    //width: 300px;
    cursor: pointer;
  &:hover {
    color: #DA135A;
    transform: scale(1.1); // Example animation
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  }
    @media screen and (max-width: 768px) {
       
       margin-top: 50px;
   
    
       }
       @media screen and (max-width: 500px) {
       
       bottom: 0.5%;
  //display: flex;
      // width: 110%;
    
       }
 
`;

export const SocialIcon = styled.div`
  color: #DA135A; // Default color
  margin: 0 5px; // Spacing between icons
  font-size: 36px; // Icon siz\\\
cursor: pointer;
  &:hover {
    color: #DA135A;
    transform: scale(1.1); // Example animation
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  // Additional styling as needed
`;

export const RoundedSocialButtons = styled(motion.div)`
  width: auto;
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  //justify-content: space-between;

  @media screen and (max-width: 500px) {
    transform:  scale(0.85);
    
    
       }
`;

export const SocialButton = styled(motion.a)`
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 3.125rem;
  height: 3.125rem;
  border: 0.125rem solid transparent;
  padding: 0;
  text-decoration: none;
  text-align: center;           
  color: #fefefe;
  font-size: 1.5625rem;
  font-weight: normal;
  line-height: 2em;
  border-radius: 1.6875rem;
  transition: all 0.5s ease;
  //margin-right: 0.25rem;
  margin-bottom: 2rem;
  transform: scale(0.75) rotate(0deg); /* Initial state */

  &:hover,
  &:focus {
    transform: scale(0.75) rotate(360deg); /* Rotate on hover */
  }
  @media screen and (max-width: 500px) {
    margin-right: -0.2rem;
    
    
       }
`;

// Define other styled components here...


export const FacebookButton = styled(SocialButton)`
  background: #3b5998;
  &:hover,
  &:focus {
    color: #3b5998;
    background: #fefefe;
    border-color: #3b5998;
  }
`;

export const TwitterButton = styled(SocialButton)`
  background: #55acee;
  &:hover,
  &:focus {
    color: #55acee;
    background: #fefefe;
    border-color: #55acee;
  }
`;

export const LinkedinButton = styled(SocialButton)`
  background: #007bb5;
  &:hover,
  &:focus {
    color: #007bb5;
    background: #fefefe;
    border-color: #007bb5;
  }
`;

export const RedditButton = styled(SocialButton)`
  background: #ff5700; /* Reddit's color */
  &:hover,
  &:focus {
    color: #ff5700;
    background: #fefefe;
    border-color: #ff5700;
  }
`;

export const YoutubeButton = styled(SocialButton)`
  background: #bb0000; /* YouTube's color */
  &:hover,
  &:focus {
    color: #bb0000;
    background: #fefefe;
    border-color: #bb0000;
  }
`;


export const DiscordButton = styled(SocialButton)`
  background: #5865f2; /* Discord's color */
  &:hover,
  &:focus {
    color: #5865f2;
    background: #fefefe;
    border-color: #5865f2;
  }
`;

export const XButton = styled(SocialButton)`
  background: #000; /* X's (formerly Twitter) color */
  &:hover,
  &:focus {
    color: #000;
    background: #fefefe;
    border-color: #000;
  }
`;
export const InstagramButton = styled(SocialButton)`
  background: #125688;
  &:hover,
  &:focus {
    color: #125688;
    background: #fefefe;
    border-color: #125688;
  }
`;



export const TikTokButton = styled(SocialButton)`
  background: #000; /* TikTok's color */
  &:hover,
  &:focus {
    color: #000;
    background: #fefefe;
    border-color: #000;
  }
`;

export const TopLine = styled.hr`
    border: 0;
    height: 1px;
    background: #DA1A53;
    //background-image: linear-gradient(to right, #ccc, #333, #ccc);\
    background-image: linear-gradient(to right, #DA1A53, #333, #DA1A53);
    position: relative;
    top: 10%;
`;

export const BottomLine = styled.hr`
    border: 0;
    height: 1px;
    background: #DA1A53;
    background-image: linear-gradient(to right, #DA1A53, #333, #DA1A53);
   //position: fixed;
   position: relative;
   top: 87.5%;
`;




export const TextSpan = styled.span`
    color: #fff;
    padding: 0px 0px 0px 15px;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    @media screen and (max-width: 768px) {

    }
`



export const ButtonText = styled.div`
   position: relative; /* Added position: relative */
   transition: transform 0.2s ease-in-out;
   // transform: ; /* Adjust the value as per your requirements */
    padding: 0px 0px 0px 0px;
    //transform: matrix(0,0,0,0,0,0);

    transform: translateY(-12px) matrix(1, 0, 0, .8, 0, 0);
    @media screen and (max-width: 768px) {
    
    }
`



