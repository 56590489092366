import React, { useEffect, useState } from 'react';
import { news1, news2, news3 } from '../SectioLayout/NewsArticles/Data';
import NewsArticleSection from '../SectioLayout/NewsArticles/News';
import { newsInfo1, newsInfo2 } from '../SectioLayout/NewsInfoSection/Data';
import NewsInfoSection from '../SectioLayout/NewsInfoSection/NewsInfo';

const News = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const divStyle = {
    paddingTop: windowWidth <= 600 ? '20px' : '100px',
  };
  return (
  <>
        <div  style={divStyle}>
       <NewsInfoSection {...newsInfo1} />
        </div>
        <NewsInfoSection {...newsInfo2} />
    <NewsArticleSection  {...news1} />
    <NewsArticleSection  {...news2} />
    <NewsArticleSection  {...news3} />
   </>
  );
};

export default News;
