import React from 'react';
import { Button } from '../../../components/GlobalComponents/GlobalElements';
import {
    BtnWrap,
    Column1,
    Column2,
    Heading,
    Img,
    ImgWrap,
    InfoContainer,
    InfoRow,
    InfoWrapper,
    Subtitle,
    TextWrapper,
    TopLine
} from './GeneralInfoSectionElements';
function NewlineText(props) {
    const text = props.text;
    return text.split('\n').map(str => <p>{str}</p>);
  }
const InfoSection = ({isDarkMode, id, imgStart, topLine,
headline, description,  buttonLabel, graphic, alt, primary,  too}) => {
    return (
        
          <InfoContainer isDarkMode={isDarkMode} id={id} >
          
        
          
              <InfoWrapper>
                  <InfoRow imgStart={imgStart}>
                      <Column1>
                      <TextWrapper imgStart={imgStart}>
                          <TopLine isDarkMode={isDarkMode}>{topLine}</TopLine>
                          <Heading isDarkMode={isDarkMode}>{headline}</Heading>
                          <Subtitle isDarkMode={isDarkMode} ><NewlineText text ={description}/></Subtitle>
                          <BtnWrap >
                              <Button to={too}
                            
                              smooth={true}
                              duration={500}
                              spy={true}
                              exact={true}
                              offset={-80}
                              primary={primary ? 1 : 0}
                                    >{buttonLabel}</Button>
                          </BtnWrap>
                          
                        
                      </TextWrapper>
                      </Column1>


                      <Column2>
                      <ImgWrap>
                    
                        <Img  imgStart={imgStart} src={graphic} alt={alt} />
                   
                      </ImgWrap>
                      </Column2>
                      
                  </InfoRow>
              </InfoWrapper>
              </InfoContainer>  
              
        
    )
}

export default InfoSection
