import React from 'react';
import { companies1, companies2, companies3, companies4 } from '../SectioLayout/GeneralInfoSection/Data';
import InfoSection from '../SectioLayout/GeneralInfoSection/GeneralInfoSection';
const Companies = () => {
  return (
<>
<div  style={{ paddingTop: '100px' }} >
<InfoSection  {...companies1} />
        </div>

<InfoSection  {...companies2} />
<InfoSection  {...companies3} />
<InfoSection  {...companies4} />
</>
  );
};

export default Companies;
