import styled from 'styled-components';

export const SignatureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  height: 100vh;
  position: relative;
  z-index: 1;
`;

export const SignatureTitle = styled.h1`
  color: #DA1A35; // Example color, adjust as needed
  font-size: 48px;
  text-align: center;
`;
