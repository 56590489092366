//HERO SECTION DATA


export const hero1 = {
    id: 'welcome',
    isDarkMode: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Unleashing Taste, Empowering Ventures',
    headline: 'Welcome to FERACHO Brand',
    description: "Elevating Flavor, Amplifying Business! At FERACHO, we're more than just a pepper sauce destination; we're a dynamic platform fueling small business success. Join us in our journey to spice up the business world.",
    buttonLabel: 'DISCOVER',
    graphic: 'https://ferachogroup.s3.amazonaws.com/images/HeroImages/Hero1.png',
   
    divStart: true,
    imgStart: false,
    name: 'feracho-welcome',
    alt: 'FERACHO Brand Image',
    too: 'team',
    primary: true,
};


export const hero2 = {
    id: 'partnerships',
    isDarkMode: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Spicy Synergies, Boundless Opportunities',
    headline: 'Global Partnerships with FERACHO',
    description: "Join the Movement for a Spicier Tomorrow! Partnering with us means more than just delicious sauces; it's about amplifying your brand and creating a positive impact. Let's grow together and make a meaningful difference in the business and culinary world.",
    buttonLabel: 'EXPLORE',
    graphic:  'https://ferachogroup.s3.amazonaws.com/images/HeroImages/Hero2.png',
   
    divStart: true,
    imgStart: true,
    name: 'feracho-partnerships',
    alt: 'Global Partnership Image',
    too: 'team',
    dark: true,
    primary: true,
    darkText: true
};


export const hero3 = {
    id: 'wellness',
    isDarkMode: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Health is Wealth, Nurture It Fearlessly',
    headline: 'Commitment to Wellness',
    description: "Prioritize Your Well-being Fearlessly. The Feracho Brand, in its dedication to positivity and compassion, is establishing a non-profit Mental Health & Wellness organization to support holistic well-being and mental health accessibility.",
    buttonLabel: 'EXPLORE',
    graphic:  'https://ferachogroup.s3.amazonaws.com/images/HeroImages/Hero3.png',
   
    divStart: true,
    imgStart: false,
    name: 'feracho-wellness',
    alt: 'Commitment to Wellness Image',
    too: 'team',
    dark: true,
    primary: true,
    darkText: true
};




//OUR STORY DATA
export const story1 = {
    id: 'essence-feracho',
    isDarkMode: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Embracing Diversity, Elevating Businesses',
    headline: 'The essence of Feracho',
    description: "At the heart of Feracho Brand lies a commitment to diversity and excellence. From the fiery flavors of Sunny Island Pepper Sauce to the innovative media solutions of Feracho Platforms, we encapsulate a world of dynamic businesses.",
    buttonLabel: 'EXPLORE',
    graphic:  'https://ferachogroup.s3.amazonaws.com/images/OurStoryImages/Story1.png',
   
    divStart: true,
    imgStart: false,
    name: 'feracho-diversity',
    alt: 'The essence of Feracho Image',
    primary: true,
};


export const story2 = {
    id: 'feracho-signature',
    isDarkMode: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Feracho Signature - Our Flagship',
    headline: 'Crafting Iconic Brands with Feracho Signature',
    description: "Feracho Signature stands at the forefront, specializing in e-commerce brand development and marketing. Our expertise in creating distinctive brands sets us apart, making every project under our wing a testament to innovation and creativity.",
    buttonLabel: 'EXPLORE',
    graphic:  'https://ferachogroup.s3.amazonaws.com/images/OurStoryImages/Story2.png',
   
    divStart: true,
    imgStart: true,
    name: 'feracho-flagship',
    alt: 'Feracho Signature Image',
    primary: true,
};

export const story3 = {
    id: 'spectrum-innovation',
    isDarkMode: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Impact and Outreach',
    headline: 'A Spectrum of Innovation Under One Umbrella',
    description: "Feracho Brand unites a variety of subsidiaries, each excelling in its domain. From the media-rich Feracho Platforms to the compassionate Feracho Anonymous focusing on mental health, our spectrum is as diverse as it is impactful.",
    buttonLabel: 'EXPLORE', // No button label provided
    graphic:  'https://ferachogroup.s3.amazonaws.com/images/OurStoryImages/Story3.png',
   
    divStart: true,
    imgStart: false,
    name: 'feracho-spectrum',
    alt: 'Spectrum of Innovation Image',
    primary: true,
};

export const story4 = {
    id: 'global-impact',
    isDarkMode: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Impact and Outreach',
    headline: 'Making a Global Impact, One Brand at a Time',
    description: "The Feracho Brand transcends boundaries, aiming to create a global impact. Our reach extends from the culinary world to mental health advocacy, each subsidiary playing a vital role in our mission.",
    buttonLabel: 'EXPLORE',
    graphic:  'https://ferachogroup.s3.amazonaws.com/images/OurStoryImages/Story4.png',
   
    divStart: true,
    imgStart: true,
    name: 'feracho-global-impact',
    alt: 'Global Impact Image',
    primary: true,
};


//COMPANIES DATA
export const companies1 = {
    id: 'feracho-signature',
    isDarkMode: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Revolutionizing Culinary Experiences',
    headline: 'FERACHO Signature: Unleashing a World of Flavors',
    description: "Welcome to FERACHO Signature, where we're not just spicing up meals - we're transforming the culinary landscape. Our flagship product, Sunny Island Pepper Sauce, is the heart of this revolution, offering a zesty, spicy twist to every dish. As culinary visionaries, we're dedicated to innovating and elevating dining experiences, blending traditional tastes with contemporary flair. Join us on a tantalizing journey as we expand our range, inviting you to explore new horizons of flavor. Each step in our journey is marked by unique, flavorful creations, promising to be your trusted companion in the adventure of cooking. With FERACHO Signature, every meal becomes an exploration of delightful, spicy possibilities.",
    buttonLabel: 'EXPLORE',
    graphic:  'https://ferachogroup.s3.amazonaws.com/images/SignatureImages/Signaure1.png',
   
    divStart: true,
    imgStart: false,
    name: 'feracho-world-flavors',
    alt: 'FERACHO Signature Image',
    primary: true,
};

export const companies2 = {
    id: 'sunny-isle',
    isDarkMode: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Empowering Entrepreneurs, Supporting Mental Health',
    headline: 'Sunny Isle Pepper Sauce: Spice Up Business & Wellness',
    description: "Sunny Isle Pepper Sauce is more than a condiment; it's a commitment. As Feracho Brand's first sponsored product, it fuels small business growth and mental health awareness. Every purchase is a step towards shared prosperity and well-being. Join us with #FerachosSauce and #SunnyIsle – taste the change!",
    buttonLabel: 'EXPLORE',
    graphic:  'https://ferachogroup.s3.amazonaws.com/images/SignatureImages/Signaure2.png',
   
    divStart: true,
    imgStart: true,
    name: 'sunny-isle-pepper-sauce',
    alt: 'Sunny Isle Pepper Sauce Image',
    primary: true,
};


export const companies3 = {
    id: 'feracho-flare',
    isDarkMode: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Experience the Magic of Every Celebration with Feracho Flare',
    headline: 'Unforgettable Events, Custom-Tailored for You',
    description: "Ignite your celebrations with Feracho Flare – your go-to for bespoke balloon artistry and unique party treats in Hampton Roads, Virginia. We offer custom, handcrafted designs for every occasion, ensuring each event is memorable and one-of-a-kind. From corporate events to children's parties, we add a personal touch that leaves a lasting impression. Ready to dazzle your guests? Discover the Feracho Flare difference – where no two events are ever the same.",
    buttonLabel: 'EXPLORE',
    graphic:  'https://ferachogroup.s3.amazonaws.com/images/SignatureImages/Signaure3.png',
   
    divStart: true,
    imgStart: false,
    name: 'feracho-event-flare',
    alt: 'Feracho Flare Event Image',
    primary: true,
};


export const companies4 = {
    id: 'feracho-flavors',
    isDarkMode: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Savor the Fusion - Feracho Flavors: A Culinary Journey',
    headline: "Atlanta's Gateway to a World of Exquisite Tastes",
    description: "Discover a world where the vibrant rhythms of Jamaica, the spirited essence of Trinidad, and the diverse palate of America blend seamlessly. Feracho Flavors, located in the heart of Atlanta, is a culinary haven where traditional dishes meet innovative fusion, creating an unforgettable umami experience. Our catering service offers an extensive array of choices, from authentic classics to daring new combinations that promise a burst of flavor in every bite. Whether you're planning an intimate gathering or a grand celebration, we tailor our menu to your taste, ensuring a personalized and memorable dining experience. With Feracho Flavors, every meal is a celebration of culture, taste, and culinary excellence.",
    buttonLabel: 'EXPLORE',
    graphic:  'https://ferachogroup.s3.amazonaws.com/images/SignatureImages/Signaure4.png',
   
    divStart: true,
    imgStart: true,
    name: 'feracho-atlanta-flavors',
    alt: 'Feracho Flavors Image',
    primary: true,
};

//NEWSLETTER DATA




///CONTACT US DATA
export const contact1 = {
    id: 'all-ears',
    isDarkMode: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Open Lines of Communication',
    headline: 'We’re all Ears',
    description: "We're Here to Listen. Whether you have a question, suggestion, or need partnership information, our team is ready to assist you.",
    buttonLabel: 'CONTACT US',
    graphic:  'https://ferachogroup.s3.amazonaws.com/images/ContactImages/Contact1.png',
   
    divStart: true,
    imgStart: false,
    name: 'feracho-all-ears',
    alt: 'Contact Image - We’re all Ears',
    too: 'team',
    primary: true,
};


export const contact2 = {
    id: 'grow-with-feracho',
    isDarkMode: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Networking for Success',
    headline: 'Grow with FERACHO',
    description: "Connect and Grow with Us. Reach out to us through our social media channels or email us directly to explore opportunities for collaboration and growth.",
    buttonLabel: 'CONNECT NOW',
    graphic:  'https://ferachogroup.s3.amazonaws.com/images/ContactImages/Contact2.png',
   
    divStart: true,
    imgStart: true,
    name: 'feracho-grow',
    alt: 'Contact Image - Grow with FERACHO',
    too: 'team',
    primary: true,
};
