import React from 'react';
import { Button } from '../../../components/GlobalComponents/GlobalElements';
import {
    BtnWrap,
    Column,
    ContactContainer,
    ContactRow,
    ContactWrapper,
    Heading,
    Img,
    ImgWrap,
    Subtitle,
    TextWrapper,
    TopLine
} from './ContactElements';



const ContactGridSection = ({
    img, alt, topLine, headline, description, buttonLabel, to,
    img2, alt2, topLine2, headline2, description2, buttonLabel2, to2
}) => {
    return (
        <ContactContainer>
            <ContactWrapper>
                <ContactRow>
                    <Column>
                        <TextWrapper>
                            <ImgWrap>
                                <Img src={img} alt={alt} />
                            </ImgWrap>
                            <TopLine>{topLine}</TopLine>
                            <Heading>{headline}</Heading>
                            <Subtitle>{description}</Subtitle>
                            <BtnWrap>
                                <Button to={to}>{buttonLabel}</Button>
                            </BtnWrap>
                        </TextWrapper>
                    </Column>

                    <Column>
                        <TextWrapper>
                            <ImgWrap>
                                <Img src={img2} alt={alt2} />
                            </ImgWrap>
                            <TopLine>{topLine2}</TopLine>
                            <Heading>{headline2}</Heading>
                            <Subtitle>{description}</Subtitle>
                            <BtnWrap>
                                <Button to={to2}>{buttonLabel2}</Button>
                            </BtnWrap>
                        </TextWrapper>
                    </Column>
                </ContactRow>
            </ContactWrapper>
        </ContactContainer>
    );
};

export default ContactGridSection;
