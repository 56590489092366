import React from 'react';
import { IoLogoFacebook, IoLogoInstagram, IoLogoTiktok, IoLogoYoutube } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import ferD from '../../media/images/Feracho-BlackMain.png'; // Dark mode company logo
import ferL from '../../media/images/Feracho-WhiteMain.png';
import bird from '../../media/images/FooterSymbol.png';
import sig from '../../media/images/SunnyIslandLogo.png';
import { BrandDescription, BrandHeader, BrandLogo, BrandSubHeader, Column, ColumnsDiv, EmphasizedQuotePart, FeaturedBrand, FooterContainer, InputForm, Logo, LogoContainer, NewsletterHeader, Quote, SocialIcon, SocialIcons, SubColumn, SubColumnLink, Symbol } from './FooterElements';
const Footer = ({isDarkMode}) => {
  return (
    <FooterContainer>
      <Column>
       
      <LogoContainer>
  <Link to="/">
  <Logo src={isDarkMode ? ferL : ferD} alt="Company Logo" />
  </Link>
</LogoContainer>
        <ColumnsDiv>
        <SubColumn>
          <SubColumnLink to="/">Home</SubColumnLink>
          <SubColumnLink to="/Signature">Feracho Signature</SubColumnLink>
          <SubColumnLink to="/News">Insider Updates</SubColumnLink>
          <SubColumnLink to="/Mental">Mental Health Awareness</SubColumnLink>
        </SubColumn>
        <SubColumn>
          <SubColumnLink to="/Story">Our Story</SubColumnLink>
          <SubColumnLink to="/Companies">Companies</SubColumnLink>
          <SubColumnLink to="/Contact">Contact</SubColumnLink>
          <SubColumnLink to="/Sunny Island Pepper Sauce">Sunny Island Pepper Sauce</SubColumnLink>
        </SubColumn>
        </ColumnsDiv>


        <NewsletterHeader>SUBSCRIBE TO NEWSLETTER</NewsletterHeader>
        <InputForm>
          <input type="email" placeholder="Enter your email" />
          <button type="submit">Submit</button>
        </InputForm>
      </Column>

      <Column>
        <Symbol src={bird}/>
        <hr />
        <SocialIcons>
          <SocialIcon><IoLogoInstagram /></SocialIcon>
          <SocialIcon><IoLogoFacebook /></SocialIcon>
          <SocialIcon><IoLogoTiktok /></SocialIcon>
          <SocialIcon><IoLogoYoutube /></SocialIcon>
        </SocialIcons>
      </Column>

      <Column>
 
        <FeaturedBrand>
        <Quote>“Health, happiness, and self-care are your  compass to a fulfilled life.
        <EmphasizedQuotePart>Prioritize them fearlessly. </EmphasizedQuotePart></Quote>
        <div>-Feracho Brand</div>
          <BrandHeader>FEATURED BRAND</BrandHeader>
          <BrandLogo src={sig}/>
          <BrandSubHeader>SUNNY ISLAND PEPPER SAUCE</BrandSubHeader>
          <BrandDescription>OUR FIRST SPONSORED BRAND! A NICE SPICY SAUCE THAT ADDS FLAVOR TO EACH BITE!</BrandDescription>
        </FeaturedBrand>
      </Column>
    </FooterContainer>
  );
}

export default Footer;
